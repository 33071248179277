// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import getRandomArbitraryInt from '@bodhi-project/components/lib/methods/getRandomArbitraryInt'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import Link from '../link'
import '../link/style.less'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Header */
const Header = ({ data }) => (
  <header>
    <h1>
      <div>
        <Link to="/digital-sovereignty">
          <span>P</span>
        </Link>
        <Link to="#">
          <span>R</span>
        </Link>
        <Link to="#">
          <span>I</span>
        </Link>
        <Link to="#">
          <span>S</span>
        </Link>
        <Link to="#">
          <span>M</span>
        </Link>
        <Link to="#">
          <span>A</span>
        </Link>
      </div>
      <div>
        <Link to="#">
          <span>D</span>
        </Link>
        <Link to="#">
          <span>E</span>
        </Link>
        <Link to="#">
          <span>S</span>
        </Link>
        <Link to="#">
          <span>I</span>
        </Link>
        <Link to="#">
          <span>G</span>
        </Link>
        <Link to="#">
          <span>N</span>
        </Link>
      </div>
    </h1>
    <div className="prismagrams">
      <div>
        <div style={{ background: '#8e354a' }}>
          <GatsbyImage
            image={getImage(data.prismagram1)}
            durationFadeIn={getRandomArbitraryInt(150, 450)}
          />
        </div>
        <div style={{ background: '#e83015' }}>
          <GatsbyImage
            image={getImage(data.prismagram2)}
            durationFadeIn={getRandomArbitraryInt(150, 450)}
          />
        </div>
        <div style={{ background: '#563f2e' }}>
          <GatsbyImage
            image={getImage(data.prismagram3)}
            durationFadeIn={getRandomArbitraryInt(150, 450)}
          />
        </div>
        <div style={{ background: '#6c6a2d' }}>
          <GatsbyImage
            image={getImage(data.prismagram4)}
            durationFadeIn={getRandomArbitraryInt(150, 450)}
          />
        </div>
        <div style={{ background: '#1c1c1c' }}>
          <GatsbyImage
            image={getImage(data.prismagram5)}
            durationFadeIn={getRandomArbitraryInt(150, 450)}
          />
        </div>
        <div style={{ background: '#622954' }}>
          <GatsbyImage
            image={getImage(data.prismagram6)}
            durationFadeIn={getRandomArbitraryInt(150, 450)}
          />
        </div>
      </div>
      <div>
        <div style={{ background: '#08192d' }}>
          <GatsbyImage
            image={getImage(data.prismagram7)}
            durationFadeIn={getRandomArbitraryInt(150, 450)}
          />
        </div>
        <div style={{ background: '#0f4c3a' }}>
          <GatsbyImage
            image={getImage(data.prismagram8)}
            durationFadeIn={getRandomArbitraryInt(150, 450)}
          />
        </div>
        <div style={{ background: '#261e47' }}>
          <GatsbyImage
            image={getImage(data.prismagram9)}
            durationFadeIn={getRandomArbitraryInt(150, 450)}
          />
        </div>
        <div style={{ background: '#005caf' }}>
          <GatsbyImage
            image={getImage(data.prismagram10)}
            durationFadeIn={getRandomArbitraryInt(150, 450)}
          />
        </div>
        <div style={{ background: '#828282' }}>
          <GatsbyImage
            image={getImage(data.prismagram11)}
            durationFadeIn={getRandomArbitraryInt(150, 450)}
          />
        </div>
        <div style={{ background: '#77428d' }}>
          <GatsbyImage
            image={getImage(data.prismagram12)}
            durationFadeIn={getRandomArbitraryInt(150, 450)}
          />
        </div>
      </div>
    </div>
  </header>
)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Header
