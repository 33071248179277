// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import compose from 'recompose/compose'
import { connect } from 'react-redux'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { useMediaQuery } from 'react-responsive'

import InitializeMeta from '@bodhi-project/seo/lib/InitializeMeta'
import UpdateTitle from '@bodhi-project/seo/lib/UpdateTitle'
import WebsiteSchema from '@bodhi-project/seo/lib/WebsiteSchema'
import OrganisationSchema from '@bodhi-project/seo/lib/OrganisationSchema'

import MediaState from '@bodhi-project/components/lib/media-state'

import '@bodhi-project/components/lib/containers/default-container.less'

import '@bodhi-project/antrd/lib/4.10.3/style/index.css'

import '@bodhi-project/components/lib/containers/default-container.less'
import '@bodhi-project/components/lib/containers/small-default-container.less'
import '@bodhi-project/components/lib/containers/small-container.less'
import '@bodhi-project/components/lib/containers/golden-major-container.less'
import '@bodhi-project/components/lib/containers/big-container.less'
import '@bodhi-project/components/lib/containers/three-quarters-container.less'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import data from '../../seo/data.json'

import Header from '../header'
import '../header/style.less'

import Footer from '../footer'
import '../footer/style.less'

import indexImage from '../../images/banners/launch.jpg'

import { updateMediaState, updateScreenSizeState } from '../../state/actions'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Styles
import '../../styles/index.less'
import '../../styles/type.less'
import '../../styles/colors.less'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React

export const query = graphql`
  query {
    prismagram1: file(relativePath: { eq: "prismagram1.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 900
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    prismagram2: file(relativePath: { eq: "prismagram2.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 900
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    prismagram3: file(relativePath: { eq: "prismagram3.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 900
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    prismagram4: file(relativePath: { eq: "prismagram4.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 900
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    prismagram5: file(relativePath: { eq: "prismagram5.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 900
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    prismagram6: file(relativePath: { eq: "prismagram6.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 900
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    prismagram7: file(relativePath: { eq: "prismagram7.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 900
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    prismagram8: file(relativePath: { eq: "prismagram8.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 900
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    prismagram9: file(relativePath: { eq: "prismagram9.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 900
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    prismagram10: file(relativePath: { eq: "prismagram10.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 900
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    prismagram11: file(relativePath: { eq: "prismagram11.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 900
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    prismagram12: file(relativePath: { eq: "prismagram12.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 900
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`

// ----------------------------------------------------------------------------
// ----------------------------------------------------------------- Global SEO
// ----------------------------------------------------------------------------
const websiteSchemaData = {
  url: data.websiteUrl,
  name: data.websiteName,
  description: data.websiteDescription,
  author: data.org.name,
  publisher: data.org.name,
  image: indexImage,
}

const organisationSchemaData = {
  name: data.org.name,
  legalName: data.org.legalName,
  url: data.org.url,
  logo: `${data.org.url}${data.org.logo}`,
  foundingDate: new Date(data.org.foundingDate).toISOString(),
  founders: data.org.founders,
  streetAddress: data.orgLocation.streetAddress,
  addressLocality: data.orgLocation.addressLocality,
  addressRegion: data.orgLocation.addressRegion,
  postalCode: data.orgLocation.postalCode,
  addressCountry: data.orgLocation.addressCountry,
  telephone: data.orgContact.telephone,
  email: data.orgContact.email,
  sameAs: data.orgSocialMediaProfiles,
  image: indexImage,
}

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Layout */
class Layout extends React.Component {
  /** standard constructor */
  constructor(props) {
    super(props)

    this.state = {
      client: false,
    }
  }

  /** after mount */
  componentDidMount() {
    this.setState({ client: true })
  }

  /** on mount */
  componentDidUpdate() {
    if (typeof window !== 'undefined') {
      if (this.state.client === true) {
        const element = document.getElementById('layout')
        element.scrollTop = 0
      }
    }
  }

  /** standard renderer */
  render() {
    const { client } = this.state
    const {
      location,
      children,
      uri = '',
      lightOrDarkMode,
      updateLightOrDarkMode,
      mediaState,
      screenSizeState,
      updateMediaState,
      updateScreenSizeState,
      className = 'standard-page',
      pageContext,
      style = {},
    } = this.props

    return (
      <MediaState
        className={`layout ${className}`}
        id="layout"
        data-uri={uri}
        useMediaQuery={useMediaQuery}
        updateMediaState={updateMediaState}
        screenSizeState={screenSizeState}
        updateScreenSizeState={updateScreenSizeState}
        useEffect={useEffect}
        useState={useState}
        client={client}
        style={style}
      >
        <InitializeMeta data={{ titleTemplate: `%s | ${data.websiteName}` }} />
        <UpdateTitle title={data.websiteAbstract} />
        <WebsiteSchema data={websiteSchemaData} />
        <OrganisationSchema data={organisationSchemaData} />
        <StaticQuery
          query={query}
          render={(data) => (
            <Fragment>
              {pageContext.intl.originalPath === '/' && <Header data={data} />}
              <main role="main">{children}</main>
              {/*<Footer /> */}
            </Fragment>
          )}
        />
      </MediaState>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

// ----------------------------------------------------------------------------
// ---------------------------------------------------------------------- State
// ----------------------------------------------------------------------------
const withState = connect(
  (state) => ({
    lightOrDarkMode: state.lightOrDarkMode,
    mediaState: state.mediaState,
    screenSizeState: state.screenSizeState,
  }),
  (dispatch) => ({
    updateMediaState(payload) {
      dispatch(updateMediaState(payload))
    },
    updateScreenSizeState(payload) {
      dispatch(updateScreenSizeState(payload))
    },
  })
)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Compose
// ----------------------------------------------------------------------------
/** Compose ala FP style */
const ComposedLayout = compose(
  withState // Add state
)(Layout)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default ComposedLayout
